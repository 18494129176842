import { Injectable } from '@angular/core';
import { CrudService, ProjectVersion } from '@sympheny/project/data-access';
import {
  mapDataRequest,
  mapDataZ,
  ResponseModel,
} from '@sympheny/utils/data-access';
import { firstValueFrom, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { Note, NoteDtoSchema, NoteSchema } from './note.model';

@Injectable()
export class NoteService extends CrudService<Note> {
  public readonly guidKey = 'guid';
  public readonly key = 'notes';

  public override list(projectVersion: ProjectVersion, scenarioGuid: string) {
    if (projectVersion === 'V1') {
      return of([]);
    }
    return this.http
      .get<ResponseModel<Note[]>>(`${this.base}scenarios/${scenarioGuid}/notes`)
      .pipe(mapDataZ(NoteSchema));
  }

  /**
   * Create a Note
   */
  public override create(
    projectVersion: ProjectVersion,
    scenarioGuid: string,
    data: Partial<Note>,
  ) {
    return firstValueFrom(
      this.http
        .post<
          ResponseModel<Note>
        >(`${this.base}scenarios/${scenarioGuid}/notes`, mapDataRequest(NoteDtoSchema, data))
        .pipe(mapDataZ(NoteSchema)),
    );
  }

  /**
   * Update Note name
   */
  public override update(
    projectVersion: ProjectVersion,
    scenarioGuid: string,
    noteId: string,
    data: Partial<Note>,
  ) {
    return firstValueFrom(
      this.http
        .put<
          ResponseModel<Note>
        >(`${this.base}scenarios/notes/${noteId}`, mapDataRequest(NoteDtoSchema, data))
        .pipe(mapDataZ(NoteSchema)),
    );
  }

  public review(noteId: string) {
    return firstValueFrom(
      this.http.put<ResponseModel<Note>>(
        `${this.base}scenarios/notes/${noteId}/review`,
        {},
      ),
    );
  }

  /**
   * Delete Note
   */

  public override delete(
    projectVersion: ProjectVersion,
    scenarioGuid: string,
    noteId: string,
  ) {
    return firstValueFrom(
      this.http
        .delete(`${this.base}scenarios/notes/${noteId}`)
        .pipe(map(() => noteId)),
    );
  }
}

<sympheny-crud-table
  [actions]="actions"
  [items]="notes()"
  [disableReadonly]="true"
  [columnConfig]="columnConfig"
  [definedActions]="definedActions"
  [customActions]="customActions"
  [initialSort]="{ active: 'created', direction: 'desc' }"
  data-cy="NOTES.list"
  subject="NOTES"
>
  <span title> {{ 'NOTES.notes' | transloco }} </span>
</sympheny-crud-table>

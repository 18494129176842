import { SymphenyDate, SymphenyNumber } from '@sympheny/utils/data-access';
import { z } from 'zod';

import { EnergyCarrierSchema } from './energy-carrier.model';
import { ImportExportTypeEnum } from './import-export.model';
import { AdvancedPriceComponentSchema } from './price-component.model';
import { TimeOfUseSchema } from './project.model';

export const ImportExportV2Schema = z.object({
  capacityPriceCHFkWMonth: SymphenyNumber().nullish(),
  capacityPriceCHFkWYear: SymphenyNumber().nullish(),
  co2IntensityKgCo2kWhCo2CompensationKgCo2kWh: SymphenyNumber().nullish(),
  created: SymphenyDate().nullish(),
  dynamicCo2ProfileId: SymphenyNumber().nullish(),
  energyCarrier: EnergyCarrierSchema.extend({}).nullish(),
  energyPriceCHFkWh: SymphenyNumber().nullish(),
  fixedOmPriceCHFYear: SymphenyNumber().nullish(),
  guid: z.string().nullish(),
  hourlyEnergyPriceProfileId: SymphenyNumber().nullish(),
  hubs: z.array(
    z.object({
      hubCreated: z.string().nullish(),
      hubGuid: z.string().nullish(),
      hubName: z.string().nullish(),
      hubUpdated: z.string().nullish(),
      impexGuid: z.string().nullish(),
    }),
  ),
  maxCapacityKW: SymphenyNumber().nullish(),

  maximumHourlyEnergyAvailableProfileId: SymphenyNumber().nullish(),
  name: z.string().nullish(),
  notes: z.string().nullish(),
  priceComponents: z.array(AdvancedPriceComponentSchema).nullish(),
  product: z.string().nullish(),
  source: z.string().nullish(),
  stages: z.array(z.string()).nullish(),
  suggested: z.boolean().nullish(),
  totalAnnualEnergyAvailableKWhA: SymphenyNumber().nullish(),
  timeOfUse: z.array(TimeOfUseSchema).nullish(),
  type: ImportExportTypeEnum.nullish(),
  updated: z.string().nullish(),
  year: SymphenyNumber().nullish(),
});

export const ImportExportV2ListSchema = ImportExportV2Schema;
export type ImportExportV2List = z.infer<typeof ImportExportV2ListSchema>;

export const ImportExportV2Dtochema = ImportExportV2Schema.pick({
  capacityPriceCHFkWMonth: true,
  capacityPriceCHFkWYear: true,
  co2IntensityKgCo2kWhCo2CompensationKgCo2kWh: true,
  dynamicCo2ProfileId: true,
  energyPriceCHFkWh: true,
  fixedOmPriceCHFYear: true,
  hourlyEnergyPriceProfileId: true,
  maxCapacityKW: true,

  maximumHourlyEnergyAvailableProfileId: true,
  name: true,
  notes: true,
  priceComponents: true,
  product: true,
  source: true,
  stages: true,
  suggested: true,

  totalAnnualEnergyAvailableKWhA: true,
  type: true,
  year: true,
}).extend({
  energyCarrierGuid: z.string(),
  hubs: z.array(
    z.object({ hubGuid: z.string(), impexGuid: z.string().nullish() }),
  ),
  timeOfUses: z.array(TimeOfUseSchema).nullish(),
});

export const ImportExportV2WithProfileFilesSchema =
  ImportExportV2Dtochema.extend({
    dynamicCo2ProfileFile: z
      .object({
        encodedFile: z.string(),
        fileName: z.string(),
      })
      .nullish(),
    maximumHourlyEnergyAvailableProfileFile: z
      .object({
        encodedFile: z.string(),
        fileName: z.string(),
      })
      .nullish(),
    hourlyEnergyPriceProfileFile: z
      .object({
        encodedFile: z.string(),
        fileName: z.string(),
      })
      .nullish(),
    dynamicCo2Selected: z.boolean(),
    dynamicTarifSelected: z.boolean(),
  });

export type ImportExportV2WithProfileFiles = z.infer<
  typeof ImportExportV2WithProfileFilesSchema
>;

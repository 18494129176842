import { NgModule } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';

import { CrudListComponent } from './crud-list/crud-list.component';
import { CrudListActionComponent } from './crud-list-action/crud-list-action.component';
import { CrudTableComponent } from './crud-table/crud-table.component';
import { CrudTableDetailComponent } from './crud-table-detail/crud-table-detail.component';
import { EnergyCarrierSelectComponent } from './energy-carrier-select/energy-carrier-select.component';
import { FormSectionComponent } from './form-section/form-section.component';
import { FormSectionWithToggleComponent } from './form-section-with-toggle/form-section-with-toggle.component';
import { HubSelectComponent } from './hub-select/hub-select.component';
import { ScenarioStepComponent } from './scenario-step/scenario-step.component';
import { StagesSelectComponent } from './stages/stages-select.component';
import { SummaryComponent } from './summary/summary.component';

const _exports = [
  CrudListComponent,
  CrudTableComponent,
  HubSelectComponent,
  StagesSelectComponent,
  EnergyCarrierSelectComponent,
  CrudListComponent,
  SummaryComponent,
  ScenarioStepComponent,
  CrudListActionComponent,
  CrudTableDetailComponent,
  FormSectionComponent,
  FormSectionWithToggleComponent,
];

@NgModule({
  imports: [..._exports],

  exports: [..._exports, MatExpansionModule],
})
export class ProjectScenarioUiKitModule {}

import { SymphenyNumber } from '@sympheny/utils/data-access';
import { z } from 'zod';

import {
  ConversionTechnologyDtoV1,
  ConversionTechnologySchemaV1,
} from './conversion-technology.model.v1';
import { MustBeInstalledInHubsEnum } from './enums';
import {
  StorageTechnologyDtoV1,
  StorageTechnologySchemaV1,
} from './storage-technologies.model';

export const TechnologyPackagesSchemaV1 = z.object({
  name: z.string(),
  guid: z.string(),
  conversionTechnologies: z.array(ConversionTechnologySchemaV1),
  storageTechnologies: z.array(StorageTechnologySchemaV1),
});

export const TechnologyV2 = z.object({
  name: z.string(),
  guid: z.string(),
});
export const TechnologyPackagesSchemaV2 = z.object({
  guid: z.string().nullish(),
  name: z.string().nullish(),

  maximumConversions: z.number().nullish(),
  maximumStorages: SymphenyNumber().nullish(),

  mustBeInstalled: MustBeInstalledInHubsEnum.nullish(),
  mutuallyExclusiveGroup: z.string().nullish(),

  conversionTechnologies: z.array(TechnologyV2).nullish(),
  storageTechnologies: z.array(TechnologyV2).nullish(),
});

export const TechnologyPackagesList = z.object({
  name: z.string(),
  guid: z.string(),
  conversionTechnologies: z.array(z.string()),
  storageTechnologies: z.array(z.string()),
});

export const TechnologyPackagesListV2 = TechnologyPackagesSchemaV2.extend({});
export type TechnologyPackagesListV2_ = z.infer<
  typeof TechnologyPackagesListV2
>;

const ConversionTechnologyPackageV1 = z.object({
  guid: z.string(),
  technologyOptional: z.boolean().default(false),
  conversionTechnology: ConversionTechnologyDtoV1,
});

const StorageTechnologyPackageV1 = z.object({
  guid: z.string(),
  technologyOptional: z.boolean().default(false),
  storageTechnology: StorageTechnologyDtoV1,
});

export const TechnologyPackagesDtoV1 = z.object({
  name: z.string(),
  conversionTechnologyPackages: z.array(ConversionTechnologyPackageV1),
  storageTechnologyPackages: z.array(StorageTechnologyPackageV1),
});

export const TechnologyPackagesDtoV2 = TechnologyPackagesSchemaV2.omit({
  storageTechnologies: true,
  conversionTechnologies: true,
}).extend({
  conversionTechnologies: z.array(z.string()).nullish(),
  storageTechnologies: z.array(z.string()).nullish(),
});

export type TechnologyPackagesV1 = z.infer<typeof TechnologyPackagesSchemaV1>;
export type TechnologyPackagesV2 = z.infer<typeof TechnologyPackagesSchemaV2>;

export type TechnologyPackages = TechnologyPackagesV1 & TechnologyPackagesV2;

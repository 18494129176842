import { SymphenyUtcDate } from '@sympheny/utils/data-access';
import { z } from 'zod';

export const ScenarioNoteType = [
  'GENERAL',
  'STAGE',
  'HUB',
  'ENERGY_CARRIER',
  'DEMAND',
  'IMPORT',
  'EXPORT',
  'ONSITE',
  'CONVERSION',
  'STORAGE',
  'TECHNOLOGY_PACKAGE',
  'NETWORK',
  'LINK',
  'INTRAHUB',
] as const;

export const ScenarioNoteTypeSchema = z.enum(ScenarioNoteType);

export type ScenarioNoteType = z.infer<typeof ScenarioNoteTypeSchema>;
export const scenarioNoteTypeWithLabels = ScenarioNoteType.map((type) => ({
  label: `NOTES.type.${type}`,
  value: type,
}));

export const NoteSchema = z.object({
  guid: z.string(),
  value: z.string(),
  scenarioGuid: z.string(),
  type: ScenarioNoteTypeSchema,
  componentId: z.string(),
  userEmail: z.string(),
  needsReview: z.boolean(),
  created: SymphenyUtcDate(),
  updated: SymphenyUtcDate(),
});

export const NoteDtoSchema = NoteSchema.pick({
  value: true,
  type: true,
  componentId: true,
});

export type Note = z.infer<typeof NoteSchema>;

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SnackbarService } from '@sympheny/ui/snackbar';
import { mapResponse } from '@sympheny/utils/data-access';
import { EnvironmentService } from '@sympheny/utils/environment';
import { firstValueFrom, of } from 'rxjs';
import { ZodSchema } from 'zod';

import { ScenarioJobDatasets } from './scenario-job-result-dataset.model';
import {
  ScenarioJob,
  ScenarioJobDetail,
  ScenarioJobDetailSchema,
  ScenarioJobSchema,
} from './scenario-job.model';

export type ResultType =
  | 'general'
  | 'pareto'
  | 'cost-co2'
  | 'cost'
  | 'co2'
  | 'imports'
  | 'exports'
  | 'conv-designs'
  | 'conv-ops'
  | 'stg-designs'
  | 'stg-ops'
  | 'net-ops'
  | 'net-designs'
  | 'onsite-resources'
  | 'energy-diagram'
  | 'network-energy-diagram';

@Injectable()
export class ScenarioJobService {
  constructor(
    private readonly http: HttpClient,
    private readonly environmentService: EnvironmentService,
    private readonly snackbarService: SnackbarService,
  ) {}

  public getAll(scenarioGuids: string[]) {
    if (scenarioGuids.length === 0) {
      return of([]);
    }
    return this.http
      .post<
        ScenarioJob[]
      >(`${this.environmentService.senseApi}solver/jobs/get-scenarios`, { scenarioGuids: scenarioGuids, limit: 200 })
      .pipe(mapResponse(ScenarioJobSchema));
  }

  public getDetails(id: string) {
    return firstValueFrom(
      this.http
        .get<ScenarioJobDetail>(
          `${this.environmentService.senseApi}solver/jobs/${id}`,
        )
        .pipe(mapResponse(ScenarioJobDetailSchema)),
    );
  }

  public getResult(
    id: string,
    type: ResultType,
    schema: ZodSchema | null,
    point?: number,
    stage?: string,
    hub?: string,
  ) {
    const params: Record<string, any> = {};

    if (point) {
      params['point'] = point;
      if (stage) params['stage'] = stage;
    }
    if (hub) params['hub'] = hub;
    const request = this.http.get<ScenarioJobDatasets>(
      `${this.environmentService.senseApi}solver/results/${id}/${type}`,
      {
        params,
      },
    );
    if (!schema) return firstValueFrom(request);

    return firstValueFrom(request.pipe(mapResponse(schema)));
  }

  public download(id: string, type: 'outputFile' | 'inputFile') {
    this.getDetails(id).then((item) => {
      const file = item[type];

      if (!file) {
        this.snackbarService.error('File not available');
        return;
      }
      window.open(file);
    });
  }
}

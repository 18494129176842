<div class="panel-content">
  <div
    *ngIf="parentForm && fields"
    class="grid grid-cols-3 pb-2 gap-4"
    [class.grid-cols-1]="elementsRow === 1"
    [class.grid-cols-2]="elementsRow === 2"
    [class.grid-cols-4]="elementsRow === 4"
    [formGroup]="parentForm"
  >
    <ng-container *ngFor="let field of fields">
      <div
        *ngIf="field.type === 'blank' || parentForm.get(field.formControlName)"
      >
        <ng-container [ngSwitch]="field.type">
          <sympheny-input-number
            *ngSwitchCase="'input'"
            [formControlName]="field.formControlName"
          />
          <sympheny-checkbox
            *ngSwitchCase="'checkbox'"
            [formControlName]="field.formControlName"
          />
          <ng-container *ngSwitchCase="'blank'"></ng-container>
          <div *ngSwitchDefault>
            No configuration defined for {{ field.formControlName }}
          </div>
        </ng-container>
      </div>
    </ng-container>
  </div>
</div>

import { Sort } from '@angular/material/sort';
import { sortByDateFn, sortByFn, sortDataFn } from '@sympheny/utils/sort';
import { isDate } from 'date-fns';

import { TableColumn } from '../model/table-configuration';

export function sortTableData<T>(
  data: any[],
  sort: Sort | null,
  columns: TableColumn<T>[],
) {
  if (
    !sort ||
    !sort.active ||
    sort.direction === '' ||
    !data ||
    data.length === 0
  ) {
    return data;
  }
  // TODO use type instead!!
  const firstValue = data[0][sort.active];
  const column = columns.find((c) => c.key === sort.active);

  if (column?.sort?.mapData) {
    return sortByFn(data, column.sort.mapData, sort.direction);
  }
  const sortByField = (column?.sort?.field as string) ?? sort.active;

  if (column?.sort?.fieldType === 'date' || isDate(firstValue)) {
    return sortByDateFn(data, sortByField, sort.direction);
  }

  const [attribute, childAttribute] = sortByField.split('_');

  return sortDataFn(data, attribute, childAttribute, sort.direction);
}

import { FormatValueConfig } from '@sympheny/format-value';
import { Note } from '@sympheny/project/scenario/data-access';

type KEY = keyof Note;

export const NotesFieldConfig: Record<KEY, FormatValueConfig<Note>> = {
  guid: undefined,
  value: {
    label: 'NOTES.value.label',
    key: 'value',
  },
  scenarioGuid: undefined,
  type: {
    label: 'NOTES.type.label',
    key: 'type',
    translatePrefix: 'NOTES.type.',
    type: 'translate',
  },
  componentId: {
    label: 'NOTES.component.label',
    key: 'componentName',
  },
  userEmail: {
    label: 'NOTES.userEmail.label',
    key: 'userEmail',
  },
  needsReview: {
    label: 'NOTES.needsReview.label',
    key: 'needsReview',
  },
  created: {
    label: 'NOTES.created.label',
    key: 'created',
    type: 'datetime',
  },
  updated: {
    label: 'NOTES.updated.label',
    key: 'updated',
    type: 'datetime',
  },
};

export const noteListFields: KEY[] = [
  'type',
  'componentId',
  'value',
  'userEmail',
  'created',
  'updated',
];

import { NgFor, NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { Sort } from '@angular/material/sort';
import { TranslocoPipe } from '@ngneat/transloco';
import { ButtonComponent, InfoButtonComponent } from '@sympheny/ui/button';
import { LoaderComponent } from '@sympheny/ui/loader';
import {
  TableAction,
  TableColumn,
  TableComponent,
  TableConfiguration,
  TableDefinedAction,
  TableDefinedActionDisabled,
} from '@sympheny/ui/table';

export interface CrudTableAction {
  label: string;
  dataCy?: string;
  action: () => void;
}

@Component({
  selector: 'sympheny-crud-table-detail',
  templateUrl: './crud-table-detail.component.html',
  styleUrls: ['./crud-table-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgIf,
    InfoButtonComponent,
    NgFor,
    ButtonComponent,
    LoaderComponent,
    TableComponent,
    TranslocoPipe,
  ],
})
export class CrudTableDetailComponent<ITEM> implements OnChanges {
  @Input() public items: ITEM[];
  @Input() public tooltip?: string;
  @Input() public addLabel = 'general.addNew';
  @Input() public loading = false;
  @Input() public labelParams: Record<string, any> = {};

  @Input() public readonly: boolean;
  @Input() public canEdit: boolean;
  @Input() public disabled: boolean;

  @Input() public columns: TableColumn<ITEM>[];
  @Input() public definedActions?: TableDefinedAction<ITEM>;
  @Input() public customActions?: TableAction<ITEM>[];

  @Input() public actions: CrudTableAction[] = [];
  @Input() public subject: string;
  @Input() public initialSort?: Sort;

  public configuration: TableConfiguration<ITEM> = {
    columns: [],
    customActions: [],
  };

  public disableDefinedActions: TableDefinedActionDisabled = {};

  public ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['columns'] ||
      changes['canEdit'] ||
      changes['readonly'] ||
      changes['disabled'] ||
      changes['definedActions']
    ) {
      this.configuration = {
        columns: this.columns,
        customActions: this.customActions ?? [],
        definedActions: {
          ...(this.definedActions ?? {}),
          edit:
            this.readonly || this.disabled ? null : this.definedActions?.edit,
          view:
            (this.definedActions?.view ?? (this.readonly || this.disabled))
              ? this.definedActions?.edit
              : null,
          delete: this.readonly ? null : this.definedActions?.delete,
        } as TableDefinedAction<any>,
        initialSort: this.initialSort,
      };
    }

    if (changes['disabled']) {
      this.disableDefinedActions = {
        delete: this.disabled,
      };
    }
  }
}

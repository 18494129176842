import {
  ConversionTechnologyListV2,
  EnergyCarrier,
  Hub,
  ImportExportV2List,
  IntraHubNetworkLinkListV2,
  NetworkLink,
  NetworkTechnologyV2,
  OnSiteSolarResourceV2List,
  StorageTechnologyList,
  TechnologyPackagesListV2_,
} from '@sympheny/project/data-access';
import {
  EnergyDemandV2List,
  ScenarioNoteType,
  Stage,
} from '@sympheny/project/scenario/data-access';
import { OptionValue } from '@sympheny/ui/form';

type CompnentNote<T> = {
  guid: keyof T;
  label: keyof T;
};

export const componentNoteMap: Record<
  ScenarioNoteType,
  CompnentNote<unknown>
> = {
  GENERAL: {} as any,
  STAGE: { guid: 'guid', label: 'name' } as CompnentNote<Stage>,
  HUB: { guid: 'hubGuid', label: 'hubName' } as CompnentNote<Hub>,
  ENERGY_CARRIER: {
    guid: 'energyCarrierGuid',
    label: 'energyCarrierName',
  } as CompnentNote<EnergyCarrier>,
  DEMAND: {
    guid: 'energyDemandGuid',
    label: 'energyDemandName',
  } as CompnentNote<EnergyDemandV2List>,
  IMPORT: {
    guid: 'guid',
    label: 'name',
  } as CompnentNote<ImportExportV2List>,
  EXPORT: {
    guid: 'guid',
    label: 'name',
  } as CompnentNote<ImportExportV2List>,
  ONSITE: {
    guid: 'solarResourceGuid',
    label: 'name',
  } as CompnentNote<OnSiteSolarResourceV2List>,
  CONVERSION: {
    guid: 'conversionTechnologyGuid',
    label: 'processName',
  } as CompnentNote<ConversionTechnologyListV2>,
  STORAGE: {
    guid: 'storageTechnologyGuid',
    label: 'storageName',
  } as CompnentNote<StorageTechnologyList>,
  TECHNOLOGY_PACKAGE: {
    guid: 'guid',
    label: 'name',
  } as CompnentNote<TechnologyPackagesListV2_>,
  NETWORK: {
    guid: 'networkTechnologyGuid',
    label: 'networkTechnologyName',
  } as CompnentNote<NetworkTechnologyV2>,
  LINK: { guid: 'networkLinkGuid', label: 'name' } as CompnentNote<NetworkLink>,
  INTRAHUB: {
    guid: 'intraHubNetworkLinkGuid',
    label: 'name',
  } as CompnentNote<IntraHubNetworkLinkListV2>,
};

export const mapComponentNote = <T>(
  value: T,
  scenarioNoteType: ScenarioNoteType,
): OptionValue<string> => {
  const { guid, label } = componentNoteMap[scenarioNoteType];
  return { label: value[label], value: value[guid] };
};

export const mapComponentNotes = <T>(
  values: T[],
  scenarioNoteType: ScenarioNoteType,
): Array<OptionValue<string>> => {
  return values.map((value) => mapComponentNote(value, scenarioNoteType));
};

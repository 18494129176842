import { HubSchema } from '@sympheny/project/data-access';
import { UploadFileSchema } from '@sympheny/ui/form/model';
import { SymphenyNumber } from '@sympheny/utils/data-access';
import { z } from 'zod';

export const EnergyDemandV2Schema = z.object({
  demandProfileId: SymphenyNumber().nullish(),
  demandSalePrice: SymphenyNumber().nullish(),
  demandSalePriceProfileId: SymphenyNumber().nullish(),
  demandSalePriceScalingFactor: SymphenyNumber().nullish(),
  demandScalingFactor: SymphenyNumber().nullish(),
  energyCarrierGuid: z.string().nullish(),
  energyCarrierName: z.string().nullish(),
  energyDemandGuid: z.string().nullish(),
  energyDemandMetadataBuildingAge: z.string().nullish(),
  energyDemandMetadataBuildingType: z.string().nullish(),
  energyDemandMetadataDbOrganization: z.string().nullish(),
  energyDemandMetadataGuid: z.string().nullish(),
  energyDemandMetadataName: z.string().nullish(),
  energyDemandMetadataOption: z.string().nullish(),
  energyDemandMetadataReferencedAreaM2: SymphenyNumber().nullish(),
  energyDemandMetadataSpecificEnergyDemandValueKWhM2:
    SymphenyNumber().nullish(),
  energyDemandMetadataTotalAnnualDemand: SymphenyNumber().nullish(),
  energyDemandMetadataType: z.string().nullish(),
  energyDemandName: z.string().nullish(),
  energyDemandUserSavedMetadataGuid: z.string().nullish(),
  energyDemandUserSavedMetadataName: z.string().nullish(),
  energyDemandUserSavedMetadataReferenceArea: SymphenyNumber().nullish(),
  multiplicationFactor: SymphenyNumber().nullish(),
  multiplicationFactorPreview: SymphenyNumber().nullish(),
  reverse: z.boolean().nullish(),
  scalingFactor: SymphenyNumber().nullish(),
  stages: z.array(z.string()).nullish(),
  hubs: z.array(HubSchema).nullish(),
});

export const EnergyDemandV2ListSchema = EnergyDemandV2Schema.extend({});
export type EnergyDemandV2List = z.infer<typeof EnergyDemandV2ListSchema>;

export const EnergyDemandV2DtoSchema = EnergyDemandV2Schema.pick({
  demandProfileId: true,
  demandSalePrice: true,
  demandSalePriceProfileId: true,
  demandSalePriceScalingFactor: true,
  energyCarrierGuid: true,
  multiplicationFactor: true,
  reverse: true,
  stages: true,
  energyDemandGuid: true,
  scalingFactor: true,
}).extend({
  hubGuids: z.array(z.string()).nullish(),
  name: z.string().nullish(),
  possibleOption: z.string().nullish(),
  profileType: z.string().nullish().nullish().nullish(),
  stages: z.array(z.string()).nullish(),
  referencedAreaM2: SymphenyNumber().nullish(),
  specificEnergyDemandValueKWhM2: SymphenyNumber().nullish(),
  totalAnnualDemand: SymphenyNumber().nullish(),
});

export const EnergyDemandV2DtoWithProfileSchema =
  EnergyDemandV2DtoSchema.extend({
    demandSalePriceProfileFile: UploadFileSchema().nullish(),
    isDynamicSalePriceProfile: z.boolean(),
    demandProfileFile: UploadFileSchema().nullish(),
    profile: z.enum(['database', 'uploaded', 'saved']),
  });

export type EnergyDemandV2DtoWithProfile = z.infer<
  typeof EnergyDemandV2DtoWithProfileSchema
>;
export type EnergyDemandV2 = z.infer<typeof EnergyDemandV2Schema>;

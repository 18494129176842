import { NgIf } from '@angular/common';
import { Component, Input, ViewContainerRef } from '@angular/core';
import {
  ScenarioNoteType,
  ScenarioStore,
} from '@sympheny/project/scenario/data-access';
import { IconButtonComponent } from '@sympheny/ui/button';

import { componentNoteMap } from '../component-note.mapper';
import { NoteDialogService } from '../note-dialog.service';

@Component({
  selector: 'sympheny-add-note-btn',
  templateUrl: './add-note-btn.component.html',
  imports: [IconButtonComponent, NgIf],
})
export class AddNoteBtnComponent {
  @Input() public type: ScenarioNoteType;
  @Input() public component: any;

  constructor(
    private readonly scenarioStore: ScenarioStore,
    private readonly noteDialogService: NoteDialogService,
    private readonly viewContainerRef: ViewContainerRef,
  ) {}

  public get isVisible() {
    return this.getGuid() && this.scenarioStore.projectVersion === 'V2';
  }

  public getGuid() {
    if (!this.component) return null;
    const guidField = componentNoteMap[this.type]?.guid;
    return this.component?.[guidField];
  }

  public addNote() {
    this.noteDialogService.addForType(
      this.type,
      this.getGuid(),
      this.viewContainerRef,
    );
  }
}

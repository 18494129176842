import {
  ScenarioNoteType,
  ScenarioState,
} from '@sympheny/project/scenario/data-access';

export const ScenarioStoreNoteType: Record<
  ScenarioNoteType,
  keyof ScenarioState
> = {
  GENERAL: 'notes',
  STAGE: 'stages',
  HUB: 'hubs',
  ENERGY_CARRIER: 'energyCarriers',
  DEMAND: 'energyDemands',
  IMPORT: 'importsExports',
  EXPORT: 'importsExports',
  ONSITE: 'onSiteSolarResources',
  CONVERSION: 'conversionTechnologies',
  STORAGE: 'storageTechnologies',
  TECHNOLOGY_PACKAGE: 'technologyPackages',
  NETWORK: 'networkTechnologies',
  LINK: 'networkLinks',
  INTRAHUB: 'intraHubNetworkLink',
};

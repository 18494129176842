import { Inject, Injectable, Optional } from '@angular/core';
import { ItemState } from '@fp-tools/angular-state';
import { UploadFile } from '@sympheny/ui/form/model';
import { LoadDataService, ReloadState } from '@sympheny/utils/data-access';
import { isNotNullOrUndefined } from '@sympheny/utils/rxjs';
import {
  distinctUntilChanged,
  filter,
  firstValueFrom,
  map,
  NEVER,
  Observable,
  of,
  switchMap,
  tap,
} from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

import {
  Account,
  Organization,
  Profile,
  UserPreferences,
} from '../model/account.model';
import { PlanLimitation } from '../model/plan-limitation.model';
import { ProfileService } from '../service/profile.service';

export const isEwzOrganization = (organization: string | null) =>
  organization?.toLowerCase() === 'ewz';

@Injectable()
export class UserState extends ItemState<Profile, any> {
  private logoutActions = new Map<string, () => void>();
  public readonly profileImage$ = this.select(
    'profilePicture',
  ) as Observable<string>;
  public readonly organizationPicture$ = this.select(
    'organizationPicture',
  ) as Observable<string>;
  public readonly firstLetter$ = this.select('preferences').pipe(
    map((profileInfo: any) =>
      [
        profileInfo?.firstName?.charAt(0) ?? '',
        profileInfo?.lastName?.charAt(0) ?? '',
      ]
        .join('')
        .toUpperCase(),
    ),
  );
  public readonly preferences$ = this.select(
    'preferences',
  ) as Observable<UserPreferences>;
  public readonly account$ = this.select('account') as Observable<Account>;
  public readonly organization$ = this.select(
    'organization',
  ) as Observable<Organization>;
  public readonly planLimitation$ = this.select(
    'planLimitation',
  ) as Observable<PlanLimitation>;
  public readonly su$ = this.selectAccount('superuser') as Observable<boolean>;
  public readonly admin$ = this.selectAccount('admin') as Observable<boolean>;

  public readonly sepOn$ = this.selectOrganization(
    'sepOn',
  ) as Observable<boolean>;
  public readonly gisOn$ = this.selectOrganization(
    'gisOn',
  ) as Observable<boolean>;
  public readonly isSymphenyOrganization$ = this.selectOrganization(
    'name',
  ).pipe(map((name) => name?.toLowerCase() === 'sympheny'));
  public readonly isSymphenyAdmin$ = this.isSymphenyOrganization$.pipe(
    switchMap((sympheny) => (sympheny ? this.admin$ : of(false))),
    distinctUntilChanged(),
  );

  constructor(
    private readonly profileService: ProfileService,
    public reloadState: ReloadState,
    @Optional()
    @Inject(LoadDataService)
    private loadDataService: LoadDataService | LoadDataService[],
  ) {
    super();
    this.initialize();
    this.reloadAllData();
  }

  private reloadAllData() {
    this.selectAccount('accountGuid')
      .pipe(
        filter((guid) => !!guid),
        tap(() => this.loadAllData()),
      )
      .subscribe();
  }

  protected fetchApi(): Observable<Profile> {
    return this.profileService.profile();
  }

  public selectOrganization<K extends keyof Organization>(key: K) {
    return this.organization$?.pipe(
      map((preferences) => preferences && preferences[key]),
    );
  }

  public getPlanLimitation<K extends keyof PlanLimitation>(key: K) {
    return this.planLimitation$?.pipe(
      map((preferences) => preferences && preferences[key]),
    );
  }

  public selectPreferences<K extends keyof UserPreferences>(key: K) {
    return this.preferences$?.pipe(
      map((preferences) => preferences && preferences[key]),
    );
  }

  public selectAccount<K extends keyof Account>(key: K) {
    return this.account$?.pipe(map((account) => account && account[key]));
  }

  public loadAllData() {
    const organization = this.organisation().name as string;
    const ewz = isEwzOrganization(organization);
    const organizationId = this.organisation().id;
    this.reloadState.reload({ organization, ewz, organizationId });
    if (!this.loadDataService) return NEVER;

    return NEVER;
  }

  public isSu() {
    return this.account()?.superuser ?? false;
  }

  public isAdmin() {
    return this.account()?.admin ?? false;
  }

  public isSymphenyAdmin() {
    return (
      (this.organisation()?.name.toLowerCase() === 'sympheny' &&
        this.isAdmin()) ??
      false
    );
  }

  public isUser(email: string) {
    return this.getEmail() === email;
  }

  public getEmail() {
    return this.account()?.email ?? null;
  }

  private organisation() {
    const data = this.getData();

    return data ? data.organization : null;
  }

  public get organisationName() {
    return this.organisation()?.name ?? null;
  }

  private account() {
    const data = this.getData();

    return data ? data.account : null;
  }

  public waitForEmail(): Promise<string> {
    return firstValueFrom(
      this.selectAccount('email').pipe(isNotNullOrUndefined()),
    ) as Promise<string>;
  }

  public get planLimitation() {
    return this.getData()?.planLimitation;
  }

  public get preferences() {
    return this.getData()?.preferences;
  }

  public isGisOn() {
    return this.organisation()?.gisOn ?? false;
  }

  public isSepOn() {
    return this.organisation()?.sepOn ?? false;
  }

  public updateAccountField<K extends keyof Account>(
    key: K,
    value: Account[K],
  ) {
    this.updateField('account', { ...this.account, [key]: value } as Account);
  }

  public login() {
    this.load();
  }

  public async logout(): Promise<void> {
    for (const action of this.logoutActions.values()) {
      action();
    }
    this.reset();
    this.logoutActions.clear();
  }

  public registerLogoutAction(action: () => void) {
    const key = uuidv4();
    this.logoutActions.set(key, action);
    return key;
  }

  public removeLogoutAction(key: string) {
    this.logoutActions.delete(key);
  }

  public uploadProfilePicture(param: UploadFile) {
    return firstValueFrom(this.profileService.uploadProfilePicture(param)).then(
      (profilePicture) => this.updateField('profilePicture', profilePicture),
    );
  }

  public savePreferences(preferences: Partial<UserPreferences>): Promise<any> {
    return firstValueFrom(
      this.profileService.savePreferences({
        ...this.preferences,
        ...preferences,
      }),
    ).then((savedPreferences) =>
      this.updateField('preferences', savedPreferences),
    );
  }

  public updateField<K extends keyof Profile>(key: K, value: Profile[K]) {
    super.updateItem({ ...this.getData(), [key]: value });
    return this;
  }

  public uploadOrganisationPicture(param: UploadFile): Promise<any> {
    return firstValueFrom(
      this.profileService.uploadOrganisationPicture(param),
    ).then((organizationPicture) =>
      this.updateField('organizationPicture', organizationPicture),
    );
  }
}

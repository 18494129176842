import { AsyncPipe, NgIf } from '@angular/common';
import { Component, Inject, Optional } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { TranslocoPipe } from '@ngneat/transloco';
import { DataSetState, DatasetSummary } from '@sympheny/gis/datasets';
import { Hub } from '@sympheny/project/data-access';
import {
  DatasetFilter,
  HubFeature,
  LayerType,
  ScenarioStore,
} from '@sympheny/project/scenario/data-access';
import {
  HubChangeEvent,
  MapConfiguration,
  ScenarioMapComponent,
  ScenarioMapStore,
} from '@sympheny/project/scenario/map-gis';
import { AddNoteBtnComponent } from '@sympheny/project/scenario/note';
import { FormDialogComponent } from '@sympheny/project/scenario/ui-kit';
import {
  CheckboxComponent,
  InputComponent,
  OptionValue,
  SelectComponent,
} from '@sympheny/ui/form';
import { UserState } from '@sympheny/user/data-access';
import { SymphenyValidator } from '@sympheny/utils/validators';
import { Feature } from 'geojson';
import { map } from 'rxjs/operators';

export interface HubFormData {
  hubGuid?: string;
  gisOn: boolean;
}

@Component({
  selector: 'sympheny-hubs-form',
  templateUrl: './hubs-form.component.html',
  styleUrls: ['./hubs-form.component.scss'],
  imports: [
    FormDialogComponent,
    ReactiveFormsModule,
    InputComponent,
    MatSlideToggleModule,
    NgIf,
    SelectComponent,
    ScenarioMapComponent,
    MatFormFieldModule,
    CheckboxComponent,
    AsyncPipe,
    TranslocoPipe,
    AddNoteBtnComponent,
  ],
})
export class HubsFormComponent {
  public readonly errorMessages: Record<string, string> = {
    required: 'Hub name is required',
    unique: 'The hub name must be unique inside the scenario',
  };

  // Inputs
  public scenarioId: string;
  public definedHubs: Hub[];
  public gisOn: boolean;

  public readonly editHub: Hub | null;

  public readonly scenarioMapConfiguration: Partial<MapConfiguration> = {
    hub: { edit: true },
    enableLayer: {
      hubs: true,
      networkLinks: true,
    },
    layerSwitcher: true,
    customerLayers: true,
  };
  public readonly datasets$ = this.datasetState.datasets$.pipe(
    map((datasets) =>
      datasets.filter((dataset) => dataset.hub_labels.length > 0),
    ),
  );
  public hubLabels: OptionValue<string>[];

  public gisMapUpdated: boolean;
  public readonly form: FormGroup;
  public readonly sepOn$ = this.userState.sepOn$;
  public hubNextError: string;
  public feature: Feature<any> | null;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) private readonly data: HubFormData,
    private readonly dialogRef: MatDialogRef<HubsFormComponent>,
    private readonly userState: UserState,
    private readonly scenarioStore: ScenarioStore,
    private readonly scenarioMapStore: ScenarioMapStore,
    private readonly datasetState: DataSetState,
  ) {
    // Get necessary data from the parent component
    this.scenarioId = this.scenarioStore.getValue('scenarioId');
    this.definedHubs = this.scenarioStore.getValue('hubs');
    this.gisOn = this.data.gisOn;

    if (this.data.hubGuid) {
      this.editHub = this.definedHubs.find(
        (h) => h.hubGuid === this.data.hubGuid,
      );
    } else {
      this.editHub = null;
    }

    this.form = this.initForm();
  }

  private initForm() {
    const hubs =
      this.definedHubs?.filter(
        (hub) => !this.editHub || this.editHub.hubGuid !== hub.hubGuid,
      ) ?? [];
    let grouping = false;
    let dataset = null;
    let hubLabel = null;

    let processGeoadmin = false;
    let processSep = false;

    if (this.editHub) {
      const hubLayer = this.scenarioMapStore.getLayer(
        LayerType.hubs,
        this.data.hubGuid,
      );

      if (hubLayer && hubLayer.hubGis) {
        this.feature = hubLayer.hubGis.base_layer?.features?.[0];
        processSep = hubLayer.hubGis.sep ?? false;
        processGeoadmin = hubLayer.hubGis.geoadmin ?? false;

        if (hubLayer.hubGis.dataset_filters?.length) {
          const datasetFilter = hubLayer.hubGis.dataset_filters[0];
          grouping = true;
          dataset = this.datasetState.getOne(datasetFilter.dataset_id);
          hubLabel = datasetFilter.fields[0].value;
        }
      }
    }

    return new FormGroup({
      name: new FormControl<string>(this.editHub?.hubName ?? null, {
        validators: [
          Validators.required,
          Validators.minLength(1),
          Validators.maxLength(100),
          SymphenyValidator.unique(hubs, 'hubName'),
          SymphenyValidator.invalidCharacters,
        ],
      }),
      processSep: new FormControl(this.userState.isSepOn() && processSep),
      processGeoadmin: new FormControl(processGeoadmin),
      grouping: new FormControl(grouping),
      dataset: new FormControl(dataset, {
        asyncValidators: SymphenyValidator.conditional({
          conditionField: 'grouping',
          validator: Validators.required,
        }),
      }),
      hubLabel: new FormControl(hubLabel, {
        asyncValidators: SymphenyValidator.conditional({
          conditionField: 'grouping',
          validator: Validators.required,
        }),
      }),
    });
  }

  public changeDataset(dataset: DatasetSummary | null) {
    const hubLabels = dataset?.hub_labels ?? [];
    this.hubLabels = hubLabels.map((h) => ({ value: h, label: h }));
  }

  public submitHub() {
    if (this.form.invalid) {
      return;
    }

    const { name: hub_name, processSep, processGeoadmin } = this.form.value;
    this.gisMapUpdated = this.gisMapUpdated || processSep || processGeoadmin;

    this.gisMapUpdated = this.gisMapUpdated || this.form.dirty;

    // Submit new or edit existing
    if (this.editHub) {
      this.updateHub(hub_name);
    } else {
      this.createHub(hub_name);
    }
  }

  private createHub(hub_name: string) {
    const onSuccess = (hub: Hub) => {
      if (this.gisMapUpdated) {
        this.scenarioMapStore.create(
          LayerType.hubs,
          this.scenarioId,
          this.createHubLayer(hub.hubGuid),
        );
      }

      // Close dialog
      this.dialogRef.close({
        message: 'Hub successfully added!',
      });
    };
    this.scenarioStore.create('hubs', { hubName: hub_name }, { onSuccess });
  }

  private createHubLayer(hub_id: string): Partial<HubFeature> {
    const { processSep, processGeoadmin, name: hub_name } = this.form.value;

    return {
      hub_id,
      hub_name,
      feature: this.feature,
      dataset_filters: this.datasetFilter,
      sep: processSep,
      geoadmin: processGeoadmin,
    };
  }

  private updateHub(hub_name: string) {
    const onSuccess = () => {
      // Post changes to map
      this.updateGis();
      // Close
      this.dialogRef.close({
        message: 'Hub successfully updated!',
      });
    };

    if (this.editHub.hubName === hub_name) {
      onSuccess();
      return;
    }

    this.scenarioStore.update(
      'hubs',
      this.data.hubGuid,
      { hubName: hub_name },
      { onSuccess },
    );
  }

  private updateGis() {
    if (this.gisMapUpdated) {
      this.scenarioMapStore.update(
        LayerType.hubs,
        this.scenarioId,
        this.editHub.hubGuid,
        this.createHubLayer(this.editHub.hubGuid),
      );
    }
  }

  private get datasetFilter(): DatasetFilter[] {
    const { grouping, dataset, hubLabel } = this.form.value;
    return grouping
      ? [
          {
            dataset_id: dataset,
            fields: [
              { default_name: 'hub_label', value: hubLabel, custom_name: '' },
            ],
          },
        ]
      : [];
  }

  public updateHubFeature(event: HubChangeEvent) {
    this.feature = event.feature;
    this.gisMapUpdated = event.mapChanged;
  }
}

import { Component, computed, ViewContainerRef } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { TranslocoPipe } from '@ngneat/transloco';
import {
  Note,
  NoteSchema,
  NoteService,
  ScenarioStore,
} from '@sympheny/project/scenario/data-access';
import {
  ColumnConfig,
  CrudTableAction,
  CrudTableComponent,
} from '@sympheny/project/scenario/ui-kit';
import { SnackbarService } from '@sympheny/ui/snackbar';
import { TableAction, TableDefinedAction } from '@sympheny/ui/table';
import { UserState } from '@sympheny/user/data-access';

import { componentNoteMap } from '../component-note.mapper';
import { NoteDialogService } from '../note-dialog.service';
import { noteListFields, NotesFieldConfig } from '../note-list.field-config';
import { ScenarioStoreNoteType } from '../scenario-store-note-type';

@Component({
  selector: 'sympheny-scenario-note-list',
  templateUrl: './note-list.component.html',
  imports: [CrudTableComponent, TranslocoPipe],
})
export class NoteListComponent {
  private readonly notesSignal = toSignal(
    this.scenarioStore.selectValue('notes'),
  );

  public readonly notes = computed(() => {
    const notes = this.notesSignal();

    const types = Array.from(new Set(notes.map((n) => n.type)));

    const typesValues = types.reduce((acc, type) => {
      acc[type] = this.scenarioStore.getValue(ScenarioStoreNoteType[type]);

      return acc;
    }, {});

    const mapNote = (n: Note) => {
      const mapper = componentNoteMap[n.type];
      const component = typesValues[n.type]?.find(
        (c) => c[mapper.guid] === n.componentId,
      );

      return {
        ...n,
        componentName: component?.[mapper.label] ?? n.componentId,
      };
    };

    return notes.map(mapNote);
  });

  public readonly actions: CrudTableAction[] = [
    {
      label: 'general.addNew',
      dataCy: 'create.note',
      action: () => this.noteDialogService.add(this.viewContainerRef),
    },
  ];

  public readonly columnConfig: ColumnConfig<Note> = {
    summaryFields: noteListFields,
    fieldConfig: NotesFieldConfig,
    schema: NoteSchema,
  };

  public readonly customActions: TableAction<Note>[] = [
    {
      tooltip: 'NOTES.confirm.note',
      icon: 'check',
      hidden: (item) => !item.needsReview,
      action: (item: Note) =>
        this.noteService
          .review(item.guid)
          .then(() => {
            this.snackbarService.info('NOTES.MESSAGES.confirm.success');
            this.scenarioStore.reload('notes');
          })
          .catch(() => {
            this.snackbarService.error('NOTES.MESSAGES.confirm.error');
          }),
    },
  ];

  public readonly definedActions: TableDefinedAction<Note> = {
    label: 'note',
    edit: (element) =>
      this.noteDialogService.edit(element, this.viewContainerRef),
    delete: (element) => this.onDelete(element),
    disableEdit: (element) => {
      return !this.userState.isUser(element.userEmail);
    },
    disableDelete: (element) => {
      return !this.userState.isUser(element.userEmail);
    },
  };

  constructor(
    private readonly noteDialogService: NoteDialogService,
    private readonly viewContainerRef: ViewContainerRef,
    private readonly scenarioStore: ScenarioStore,
    private readonly userState: UserState,
    private readonly noteService: NoteService,
    private readonly snackbarService: SnackbarService,
  ) {}

  public onDelete(note: Pick<Note, 'guid'>) {
    return this.scenarioStore.delete('notes', note.guid);
  }
}

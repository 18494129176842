import {
  NgFor,
  NgIf,
  NgSwitch,
  NgSwitchCase,
  NgSwitchDefault,
} from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {
  ControlContainer,
  FormGroup,
  ReactiveFormsModule,
} from '@angular/forms';
import { CheckboxComponent, InputNumberComponent } from '@sympheny/ui/form';

export type FormSectionField<T> = {
  type: 'input' | 'checkbox' | 'blank';
  formControlName: string;
};

@Component({
  selector: 'sympheny-scenario-form-section-content',
  templateUrl: './form-section-content.component.html',
  imports: [
    ReactiveFormsModule,
    NgFor,
    NgIf,
    NgSwitch,
    NgSwitchCase,
    NgSwitchDefault,
    InputNumberComponent,
    CheckboxComponent,
  ],
})
export class FormSectionContentComponent<T> implements OnInit {
  @Input() public fields: FormSectionField<T>[];
  @Input() public elementsRow: 1 | 2 | 3 | 4 = 4;

  public parentForm: FormGroup;

  constructor(private readonly controlContainer: ControlContainer) {}

  public ngOnInit() {
    this.parentForm = this.controlContainer.control as FormGroup;
  }
}

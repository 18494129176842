<sympheny-dialog-form
  [form]="noteForm"
  [formGroup]="noteForm"
  (submitForm)="submitForm()"
  contentLabel="NOTES.notes"
  [editMode]="!!noteId"
>
  <div content class="grid grid-cols-3 gap-4">
    <sympheny-select formControlName="type" [options]="scenarioNoteTypeWithLabels"/>
    <sympheny-select formControlName="componentId" [options]="componentOptions()"/>
    <sympheny-textarea class="col-span-3"
                       formControlName="value"/>

  </div>
</sympheny-dialog-form>

import { Injectable, ViewContainerRef } from '@angular/core';
import { Note, ScenarioNoteType } from '@sympheny/project/scenario/data-access';
import { DIALOG_WIDTH, DialogService } from '@sympheny/ui/dialog';

import { NoteData, NoteEditComponent } from './note-edit/note-edit.component';

@Injectable()
export class NoteDialogService {
  constructor(private dialogService: DialogService) {}

  public addForType(
    type: ScenarioNoteType,
    componentId: string,
    viewContainerRef: ViewContainerRef,
  ) {
    this.openDialog({ note: { type, componentId } }, viewContainerRef);
  }

  public add(viewContainerRef: ViewContainerRef) {
    this.openDialog({ note: null }, viewContainerRef);
  }

  public edit(note: Note, viewContainerRef: ViewContainerRef) {
    this.openDialog({ note }, viewContainerRef);
  }

  private openDialog(data: NoteData, viewContainerRef: ViewContainerRef) {
    this.dialogService.openDialog(
      NoteEditComponent,
      data,
      DIALOG_WIDTH.xlarge,
      viewContainerRef,
    );
  }
}

import { Component, computed, Inject, Optional, signal } from '@angular/core';
import { FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  Note,
  ScenarioNoteType,
  scenarioNoteTypeWithLabels,
  ScenarioStore,
} from '@sympheny/project/scenario/data-access';
import { DialogFormComponent } from '@sympheny/ui/dialog';
import {
  SelectComponent,
  SymphenyFormControl,
  TextareaComponent,
} from '@sympheny/ui/form';

import { mapComponentNotes } from '../component-note.mapper';
import { NotesFieldConfig } from '../note-list.field-config';
import { ScenarioStoreNoteType } from '../scenario-store-note-type';

export interface NoteData {
  note: Note | null;
}

@Component({
  selector: 'sympheny-note-edit',
  templateUrl: './note-edit.component.html',
  imports: [
    ReactiveFormsModule,
    TextareaComponent,
    SelectComponent,
    DialogFormComponent,
  ],
})
export class NoteEditComponent {
  public readonly noteForm: FormGroup;
  public readonly noteId: string | null;

  public readonly scenarioNoteTypeWithLabels = scenarioNoteTypeWithLabels;

  private readonly selectedType = signal<ScenarioNoteType>(null);
  public readonly componentOptions = computed(() => {
    const type = this.selectedType();

    if (!type) return [];

    if (type === 'GENERAL') return [{ label: 'Currency', value: 'currency' }];

    return mapComponentNotes(
      this.scenarioStore.getValue(ScenarioStoreNoteType[type]) as any[],
      type,
    );
  });

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) readonly data: NoteData,
    @Optional() private readonly dialogRef: MatDialogRef<NoteEditComponent>,
    private readonly scenarioStore: ScenarioStore,
  ) {
    const note = data?.note ?? {};
    this.noteId = note ? note.guid : null;

    this.noteForm = new FormGroup({
      value: new SymphenyFormControl(note?.value, NotesFieldConfig.value, {
        validators: Validators.required,
      }),
      componentId: new SymphenyFormControl(
        note?.componentId,
        NotesFieldConfig.componentId,
        { validators: Validators.required },
      ),
      type: new SymphenyFormControl(note?.type, NotesFieldConfig.type, {
        validators: Validators.required,
      }),
    });
    this.noteForm.get('type')?.valueChanges.subscribe((type) => {
      this.noteForm.get('componentId').setValue(null);

      this.selectedType.set(type);
    });
    this.selectedType.set(note?.type ?? 'STAGE');

    this.noteForm.markAsUntouched();
  }

  public submitForm() {
    if (this.noteForm.invalid) {
      return;
    }

    const note: Partial<Note> = this.noteForm.value;

    if (this.noteId) {
      return this.update(note);
    } else {
      return this.create(note);
    }
  }

  private create(note: Partial<Note>) {
    const onSuccess = () => {
      this.dialogRef.close();
    };

    return this.scenarioStore.create('notes', note, { onSuccess });
  }

  private update(note: Partial<Note>) {
    const onSuccess = () => {
      this.dialogRef.close();
    };

    return this.scenarioStore.update('notes', this.noteId, note, { onSuccess });
  }
}
